body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  padding: 5vh 10vw 0 10vw;
  color: #121314;
}

h1 {
  font-size: 4.5rem;
  font-weight: 500;
  margin-bottom: 0;
}

h2 {
  font-size: 2.2rem;
}

p {
  font-weight: 300;
  line-height: 1.4;
  max-width: 28rem;
}

a {
  text-decoration: none;
  white-space: nowrap;
  color: #121314;
  position: relative;
}

a:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 66%;
  left: -0.1em;
  right: -0.1em;
  bottom: 0;
  transition: top 200ms cubic-bezier(0, 0.8, 0.13, 1);
  background-color: rgba(208, 93, 40, 0.75);
}

a:hover:after {
  top: 0%;
}

.posts {
  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  a:after {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }
}

.post {
  &__info {
    font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1rem;
    font-weight: lighter;
    margin-bottom: 0.5rem;
  }
}

.page,
.post {
  display: flex;
  flex-direction: column;
  min-height: 90vh;

  main {
    flex: 1;
    margin-top: auto;
  }

  footer {
    font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: small;

    a {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }

    a:after {
      display: none;
    }
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121314;
    color: #f5f5f7;
  }

  a {
    color: #f5f5f7;
  }
}

/* Styles for desktop screens (typically larger than 1024px) */
@media (min-width: 1025px) {
  /* Add your desktop-specific CSS rules here */
}

/* Styles for tablet screens (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  /* Add your tablet-specific CSS rules here */
}

/* Styles for phone screens (up to 767px) */
@media (max-width: 767px) {
  .posts {
    margin-top: 0;

    ul {
      columns: 1;
    }
  }
}